export default function RatingSvg() {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='20'
			height='20'
			viewBox='0 0 20 20'
			fill='none'
		>
			<g clipPath='url(#clip0_186_648)'>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M8.48655 2.95821C9.08223 1.66676 10.9177 1.66676 11.5134 2.95821L13.1476 6.50107L17.022 6.96045C18.4343 7.12791 19.0015 8.87355 17.9574 9.83917L15.0929 12.4882L15.8533 16.3149C16.1304 17.7099 14.6455 18.7887 13.4045 18.0941L9.99998 16.1884L6.59547 18.0941C5.35445 18.7887 3.86951 17.7099 4.14669 16.3149L4.90706 12.4882L2.04259 9.83917C0.998421 8.87355 1.56561 7.12791 2.97794 6.96045L6.85238 6.50108L8.48655 2.95821ZM11.6341 7.19915L9.99998 3.65629L8.36581 7.19915C8.12303 7.7255 7.62422 8.0879 7.04862 8.15615L3.17417 8.61553L6.03865 11.2645C6.46421 11.6581 6.65474 12.2444 6.54177 12.813L5.7814 16.6397L9.1859 14.734C9.69169 14.4509 10.3083 14.4509 10.814 14.734L14.2186 16.6397L13.4582 12.813C13.3452 12.2444 13.5357 11.6581 13.9613 11.2645L16.8258 8.61553L12.9513 8.15615C12.3757 8.0879 11.8769 7.7255 11.6341 7.19915Z'
					fill='white'
				/>
				<path
					d='M9.99998 3.65629L11.6341 7.19915C11.8769 7.7255 12.3757 8.0879 12.9513 8.15615L16.8258 8.61553L13.9613 11.2645C13.5357 11.6581 13.3452 12.2444 13.4582 12.813L14.2186 16.6397L10.814 14.734C10.3083 14.4509 9.69169 14.4509 9.1859 14.734L5.7814 16.6397L6.54177 12.813C6.65474 12.2444 6.46421 11.6581 6.03865 11.2645L3.17417 8.61553L7.04862 8.15615C7.62422 8.0879 8.12303 7.7255 8.36581 7.19915L9.99998 3.65629Z'
					fill='white'
				/>
			</g>
			<defs>
				<clipPath id='clip0_186_648'>
					<rect width='20' height='20' fill='white' />
				</clipPath>
			</defs>
		</svg>
	)
}
