import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/[locale]/banner.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/[locale]/brands.jsx");
;
import(/* webpackMode: "eager" */ "/app/app/[locale]/components/identifiers/ProductModal.jsx");
;
import(/* webpackMode: "eager" */ "/app/app/[locale]/components/modals/homeModal.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/[locale]/components/productCard.jsx");
;
import(/* webpackMode: "eager" */ "/app/app/[locale]/main.scss");
;
import(/* webpackMode: "eager" */ "/app/app/[locale]/product/[productId]/productInfo.jsx");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
