"use client";
import { useLocale } from "next-intl";
import Link from "next/link";
import { useRouter } from "next/navigation";
import { useContext, useState } from "react";
import CatalogSvg from "../assets/frags/catalogSvg";
import Logo from "../assets/frags/logo";
import AuthContext from "../context/authContext";
import Catalog from "./catalog";
import Search from "./search/search";
import { DynamicCollectionNav } from "./daynamicnav";
import RenderLanguageOptions from "./language";
import Headroom from "react-headroom";
import LoginButton from "../components/isLogin/loginButton";

export default function Header({ data, dictionary }) {
  const [scroll, setScroll] = useState(false);
  const [isCatalogOpen, setCatalogOpen] = useState(false);
  const { toggleShowLogin } = useContext(AuthContext);
  const locale = useLocale();
  const router = useRouter();

  const sortedNavbarData = data
    ?.filter((item) => item.section === "NAVBAR")
    .sort((a, b) => a.sequenceNumber - b.sequenceNumber);

  const navbarComponents = {
    NAVBAR: DynamicCollectionNav,
  };

  const toggleCatalog = () => setCatalogOpen((prev) => !prev);

  const renderNavbarItems = () => (
    <ul className="header_list">
      {sortedNavbarData?.map((item) => {
        const NavComponent = navbarComponents[item.section];
        return NavComponent ? <NavComponent key={item.id} data={item} /> : null;
      })}
    </ul>
  );

  return (
    <Headroom style={{ zIndex: 10 }}>
      <header id="top" className={scroll ? "header_active" : ""}>
        <div className="header_top">
          <div className="container header_wrap">
            <div className="header_left">
              <Link href={`/${locale}`} className="header_logo">
                <Logo />
              </Link>
              {renderNavbarItems()}
            </div>
            <div className="header_right">
              <RenderLanguageOptions />
              <div>
                <LoginButton locale={locale} dictionary={dictionary} />
              </div>
            </div>
          </div>
          <Catalog checked={isCatalogOpen} setChecked={setCatalogOpen} />
        </div>
        <div className="header_bottom">
          <div className="container header_bottom_wrapper">
            <button
              className={`header_catalog_btn ${
                isCatalogOpen ? "header_catalog_active" : ""
              }`}
              onClick={toggleCatalog}
            >
              {!isCatalogOpen ? (
                <CatalogSvg />
              ) : (
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.4106 15.5892C14.736 15.9147 15.2637 15.9147 15.5891 15.5892C15.9145 15.2638 15.9145 14.7362 15.5891 14.4107L11.1783 9.99999L15.5891 5.58925C15.9145 5.26381 15.9145 4.73617 15.5891 4.41073C15.2637 4.0853 14.736 4.0853 14.4106 4.41073L9.99984 8.82148L5.58909 4.41073C5.26366 4.0853 4.73602 4.0853 4.41058 4.41073C4.08514 4.73617 4.08514 5.26381 4.41058 5.58925L8.82133 9.99999L4.41058 14.4107C4.08515 14.7362 4.08515 15.2638 4.41058 15.5892C4.73602 15.9147 5.26366 15.9147 5.58909 15.5892L9.99984 11.1785L14.4106 15.5892Z"
                    fill="white"
                  />
                </svg>
              )}

              {dictionary.search.button}
            </button>
            <Search dictionary={dictionary} />
            <ul className="header_bottom_list">
              <li>
                <Link href={`/${locale}/basket`} prefetch={true} />
              </li>
              <li className="like">
                <Link href={`/${locale}/favorites`} prefetch={true} />
              </li>
            </ul>
          </div>
        </div>
      </header>
      <div
        className={isCatalogOpen ? "main_bg main_bg_active" : "main_bg"}
        onClick={toggleCatalog}
      ></div>
    </Headroom>
  );
}
