export function getSectionData(data, section) {
  if (!Array.isArray(data)) {
    throw new Error("First parameter must be an array.");
  }
  if (typeof section !== "string") {
    throw new Error("Second parameter must be a string.");
  }
  return data.filter((item) => item.section === section);
}

export function FindColor(data, datatype) {
  if (!Array.isArray(data)) {
    throw new Error("First parameter must be an array.");
  }
  if (typeof datatype !== "string") {
    throw new Error("Second parameter must be a string.");
  }
  return data?.filter((item) => item.type === datatype);
}

export function getImageUrl(id) {
  return `${process.env.NEXT_PUBLIC_FILE}/api/v1/file/download/${id}`;
}
export function getStaticUrl(url) {
  return `${process.env.NEXT_PUBLIC_API}${url}`;
}

export function formatCurrency(amount) {
  return new Intl.NumberFormat("ru-RU", {
    style: "currency",
    currency: "UZS",
    minimumFractionDigits: 0,
  }).format(!amount > 0 ? 0 : amount);
}
