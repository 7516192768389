import React from "react";

const Karzina = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.83333 2.5C3.3731 2.5 3 2.8731 3 3.33333C3 3.79357 3.3731 4.16667 3.83333 4.16667H4.66667C5.08388 4.16667 5.42948 4.47326 5.49045 4.87344L5.72288 8.59317C5.86009 10.7892 7.68114 12.5 9.88143 12.5H13.8333C16.1345 12.5 18 10.6345 18 8.33333C18 6.03215 16.1345 4.16667 13.8333 4.16667H7.02441C6.68121 3.19567 5.75518 2.5 4.66667 2.5H3.83333Z"
        fill="white"
      />
      <path
        d="M10.5 15.8333C10.5 16.7538 9.75381 17.5 8.83333 17.5C7.91286 17.5 7.16667 16.7538 7.16667 15.8333C7.16667 14.9129 7.91286 14.1667 8.83333 14.1667C9.75381 14.1667 10.5 14.9129 10.5 15.8333Z"
        fill="white"
      />
      <path
        d="M13.8333 17.5C14.7538 17.5 15.5 16.7538 15.5 15.8333C15.5 14.9129 14.7538 14.1667 13.8333 14.1667C12.9129 14.1667 12.1667 14.9129 12.1667 15.8333C12.1667 16.7538 12.9129 17.5 13.8333 17.5Z"
        fill="white"
      />
    </svg>
  );
};

export default Karzina;
