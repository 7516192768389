import React from "react";

const ProductSelectComponent = ({ value, options, onChange }) => {
  return (
    <div className="product_info_select_size">
      {options.map((element) => (
        <div
          key={element.value}
          onClick={() => {
            if (!element.disabled) {
              onChange(element.value);
            }
          }}
          disabled={element.disabled}
          role="button"
          className={`color-option ${
            element.disabled ? "disabled-store" : ""
          } ${value === element.value ? "active-store" : ""}`}
        >
          {element.value}
        </div>
      ))}
    </div>
  );
};

export default ProductSelectComponent;
