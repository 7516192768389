"use client";

import Image from "next/image";
import Slider from "react-slick";
import { getImageUrl } from "@/lib/functions";

export default function Brands({ data }) {
  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    variableWidth: false,
    autoplay: true,
    autoplaySpeed: 2000,
    cssEase: "linear",
  };

  return (
    <div className="brands container">
      <div className="home_top_wrap">
        <h2 className="home_title">Популярные бренды</h2>
      </div>

      <Slider {...settings} className="brands_slider">
        {data?.params.items?.map((item, index) => (
          <div key={index} className="brands_img_wrap">
            <Image
              unoptimized
              src={getImageUrl(item?.imageId)}
              alt="brand img"
              width={100}
              height={60}
              layout="responsive"
              className="brands_img"
            />
          </div>
        ))}
      </Slider>
    </div>
  );
}
