export default function StarSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <g clipPath="url(#clip0_337_2843)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.48693 2.95818C9.08262 1.66673 10.9181 1.66673 11.5138 2.95818L13.1479 6.50104L17.0224 6.96042C18.4347 7.12788 19.0019 8.87352 17.9577 9.83914L15.0933 12.4881L15.8536 16.3149C16.1308 17.7098 14.6459 18.7887 13.4049 18.094L10.0004 16.1883L6.59585 18.094C5.35483 18.7887 3.86989 17.7098 4.14707 16.3149L4.90744 12.4881L2.04297 9.83914C0.998802 8.87352 1.56599 7.12788 2.97832 6.96042L6.85276 6.50104L8.48693 2.95818ZM11.6345 7.19912L10.0004 3.65626L8.36619 7.19912C8.12341 7.72547 7.6246 8.08787 7.049 8.15612L3.17455 8.6155L6.03903 11.2645C6.46459 11.658 6.65512 12.2444 6.54215 12.8129L5.78178 16.6397L9.18629 14.734C9.69208 14.4509 10.3086 14.4509 10.8144 14.734L14.2189 16.6397L13.4586 12.8129C13.3456 12.2444 13.5361 11.658 13.9617 11.2645L16.8262 8.6155L12.9517 8.15612C12.3761 8.08787 11.8773 7.72546 11.6345 7.19912Z"
          fill="#FFC100"
        />
        <path
          d="M10.0004 3.65626L11.6345 7.19912C11.8773 7.72546 12.3761 8.08787 12.9517 8.15612L16.8262 8.6155L13.9617 11.2645C13.5361 11.658 13.3456 12.2444 13.4586 12.8129L14.2189 16.6397L10.8144 14.734C10.3086 14.4509 9.69208 14.4509 9.18629 14.734L5.78178 16.6397L6.54215 12.8129C6.65512 12.2444 6.46459 11.658 6.03903 11.2645L3.17455 8.6155L7.049 8.15612C7.6246 8.08787 8.12341 7.72547 8.36619 7.19912L10.0004 3.65626Z"
          fill="#FFC100"
        />
      </g>
      <defs>
        <clipPath id="clip0_337_2843">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
