import { useLocale } from "next-intl";
import Link from "next/link";
import React from "react";
import { usePathname } from "next/navigation";
import UzbekFlag from "../../assets/frags/uzbek";
import RussionFlag from "../../assets/frags/russionflag";
import EnglishFlag from "../../assets/frags/englishflag";

const RenderLanguageOptions = () => {
  const pathname = usePathname() || "";
  const locale = useLocale();
  const languageOptions = [
    { code: "uz", name: "UZ", image: <UzbekFlag /> },
    { code: "ru", name: "РУ", image: <RussionFlag /> },
    { code: "en", name: "EN", image: <EnglishFlag /> },
  ];

  return (
    <div className="language">
      {languageOptions.map((item) =>
        item.code === locale ? (
          <span
            key={item.code}
            className={`language_block ${item.code} hover_3`}
          >
            {item.image}
            {item.name}
          </span>
        ) : null,
      )}
      <div className="language_select">
        {languageOptions.map((item) => (
          <Link
            key={item.code}
            href={`/${item.code + pathname.slice(3)}`}
            className={`language_select_block ${
              locale === item.code ? "none" : ""
            } ${item.code} hover_3`}
            prefetch={true}
          >
            {item.image}
            {item.name}
          </Link>
        ))}
      </div>
    </div>
  );
};

export default RenderLanguageOptions;
