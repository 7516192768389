import React from "react";

const UzbekFlag = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_614_5093"
        // style="mask-type:alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="20"
        height="20"
      >
        <circle cx="10" cy="10" r="10" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_614_5093)">
        <rect
          width="6.66667"
          height="20"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 0 0)"
          fill="#0091DA"
        />
        <path
          d="M14.1959 0.83962L14.4443 0.277771L14.6927 0.83962L15.2776 0.914377L14.8462 1.3364L14.9593 1.94444L14.4443 1.59722L13.9293 1.94444L14.0424 1.3364L13.611 0.914377L14.1959 0.83962Z"
          fill="#F5F7F8"
        />
        <path
          d="M16.4181 0.83962L16.6665 0.277771L16.9149 0.83962L17.4999 0.914377L17.0684 1.3364L17.1816 1.94444L16.6665 1.59722L16.1515 1.94444L16.2646 1.3364L15.8332 0.914377L16.4181 0.83962Z"
          fill="#F5F7F8"
        />
        <path
          d="M12.2221 2.22222L11.9737 2.78406L11.3888 2.85882L11.8202 3.28084L11.7071 3.88888L12.2221 3.54166L12.7371 3.88888L12.624 3.28084L13.0554 2.85882L12.4705 2.78406L12.2221 2.22222Z"
          fill="#F5F7F8"
        />
        <path
          d="M14.1959 2.78406L14.4443 2.22222L14.6927 2.78406L15.2776 2.85882L14.8462 3.28084L14.9593 3.88888L14.4443 3.54166L13.9293 3.88888L14.0424 3.28084L13.611 2.85882L14.1959 2.78406Z"
          fill="#F5F7F8"
        />
        <path
          d="M16.6665 2.22222L16.4181 2.78406L15.8332 2.85882L16.2646 3.28084L16.1515 3.88888L16.6665 3.54166L17.1816 3.88888L17.0684 3.28084L17.4999 2.85882L16.9149 2.78406L16.6665 2.22222Z"
          fill="#F5F7F8"
        />
        <path
          d="M11.9737 4.72851L12.2221 4.16666L12.4705 4.72851L13.0554 4.80327L12.624 5.22528L12.7371 5.83333L12.2221 5.4861L11.7071 5.83333L11.8202 5.22528L11.3888 4.80327L11.9737 4.72851Z"
          fill="#F5F7F8"
        />
        <path
          d="M9.99987 4.16666L9.75148 4.72851L9.16653 4.80327L9.59797 5.22528L9.48483 5.83333L9.99987 5.4861L10.5149 5.83333L10.4018 5.22528L10.8332 4.80327L10.2483 4.72851L9.99987 4.16666Z"
          fill="#F5F7F8"
        />
        <path
          d="M16.4181 4.72851L16.6665 4.16666L16.9149 4.72851L17.4999 4.80327L17.0684 5.22528L17.1816 5.83333L16.6665 5.4861L16.1515 5.83333L16.2646 5.22528L15.8332 4.80327L16.4181 4.72851Z"
          fill="#F5F7F8"
        />
        <path
          d="M14.4443 4.16666L14.1959 4.72851L13.611 4.80327L14.0424 5.22528L13.9293 5.83333L14.4443 5.4861L14.9593 5.83333L14.8462 5.22528L15.2776 4.80327L14.6927 4.72851L14.4443 4.16666Z"
          fill="#F5F7F8"
        />
        <path
          d="M6.61719 5.29659C7.06011 5.65045 7.61089 5.77384 8.11903 5.68123C7.31885 6.24895 6.2093 6.24565 5.40452 5.60269C4.43057 4.82456 4.27182 3.40422 5.04995 2.43027C5.82807 1.45631 7.24842 1.29757 8.22237 2.07569C8.27916 2.12107 8.33318 2.16862 8.38441 2.21815C7.65271 1.92065 6.79598 2.11043 6.29175 2.74155C5.67364 3.51523 5.81934 4.65916 6.61719 5.29659Z"
          fill="#F5F7F8"
        />
        <rect
          width="6.66667"
          height="20"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 0 13.3333)"
          fill="#009B77"
        />
        <rect
          width="6.66667"
          height="20"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 0 6.66666)"
          fill="#DD2033"
        />
        <rect
          width="5"
          height="20"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 0 7.5)"
          fill="#F5F7F8"
        />
      </g>
    </svg>
  );
};

export default UzbekFlag;
