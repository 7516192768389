"use client";

import React, { useContext } from "react";
import { useRouter } from "next/navigation";
import Cookies from "js-cookie";
import AuthContext from "../../context/authContext";

const LoginButton = ({ dictionary, locale }) => {
  const router = useRouter();
  const { toggleShowLogin } = useContext(AuthContext);
  const accessToken = Cookies.get("refresh_token") ?? "";

  const handleClick = () => {
    if (accessToken) {
      router.push(`/${locale}/profile/details`);
    } else {
      toggleShowLogin(true);
    }
  };

  return (
    <div>
      <button className="header_btn" onClick={handleClick}>
        {accessToken ? dictionary.navbar.button : dictionary.navbar.isLogin}
      </button>
    </div>
  );
};

export default LoginButton;
