import React from "react";

const Increase = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M10.8337 4.99984C10.8337 4.5396 10.4606 4.1665 10.0003 4.1665C9.54009 4.1665 9.16699 4.5396 9.16699 4.99984V9.1665H5.00033C4.54009 9.1665 4.16699 9.5396 4.16699 9.99984C4.16699 10.4601 4.54009 10.8332 5.00033 10.8332H9.16699V14.9998C9.16699 15.4601 9.54009 15.8332 10.0003 15.8332C10.4606 15.8332 10.8337 15.4601 10.8337 14.9998V10.8332H15.0003C15.4606 10.8332 15.8337 10.4601 15.8337 9.99984C15.8337 9.5396 15.4606 9.1665 15.0003 9.1665H10.8337V4.99984Z"
        fill="white"
      />
    </svg>
  );
};

export default Increase;
