import React from "react";

const EnglishFlag = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_614_5077"
        // style="mask-type:alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="20"
        height="20"
      >
        <circle cx="10" cy="10" r="10" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_614_5077)">
        <rect
          y="17.5"
          width="17.5"
          height="20"
          transform="rotate(-90 0 17.5)"
          fill="#F5F7F8"
        />
        <rect
          x="10"
          y="5"
          width="2.5"
          height="10"
          transform="rotate(-90 10 5)"
          fill="#DD2033"
        />
        <rect
          x="10"
          y="10"
          width="2.5"
          height="10"
          transform="rotate(-90 10 10)"
          fill="#DD2033"
        />
        <rect
          y="15"
          width="2.5"
          height="20"
          transform="rotate(-90 0 15)"
          fill="#DD2033"
        />
        <rect
          y="20"
          width="2.5"
          height="20"
          transform="rotate(-90 0 20)"
          fill="#DD2033"
        />
        <rect
          y="10"
          width="10"
          height="10"
          transform="rotate(-90 0 10)"
          fill="#004692"
        />
        <path
          d="M7.88895 6.11111L7.39216 7.23481L6.22228 7.38432L7.08515 8.22836L6.85887 9.44444L7.88894 8.75L8.91902 9.44444L8.69273 8.22836L9.55561 7.38432L8.38572 7.23481L7.88895 6.11111Z"
          fill="#F5F7F8"
        />
        <path
          d="M7.88895 -0.555557L7.39216 0.568141L6.22228 0.717654L7.08515 1.56169L6.85887 2.77778L7.88894 2.08333L8.91902 2.77778L8.69273 1.56169L9.55561 0.717654L8.38572 0.568141L7.88895 -0.555557Z"
          fill="#F5F7F8"
        />
        <path
          d="M5.66673 2.77778L5.16994 3.90147L4.00005 4.05099L4.86293 4.89502L4.63665 6.11111L5.66672 5.41666L6.6968 6.11111L6.47051 4.89502L7.33339 4.05099L6.1635 3.90147L5.66673 2.77778Z"
          fill="#F5F7F8"
        />
        <path
          d="M3.16673 6.11111L2.66994 7.23481L1.50005 7.38432L2.36293 8.22836L2.13665 9.44444L3.16672 8.75L4.1968 9.44444L3.97051 8.22836L4.83339 7.38432L3.6635 7.23481L3.16673 6.11111Z"
          fill="#F5F7F8"
        />
        <path
          d="M3.16673 -0.555557L2.66994 0.568141L1.50005 0.717654L2.36293 1.56169L2.13665 2.77778L3.16672 2.08333L4.1968 2.77778L3.97051 1.56169L4.83339 0.717654L3.6635 0.568141L3.16673 -0.555557Z"
          fill="#F5F7F8"
        />
        <path
          d="M0.944507 2.77778L0.447717 3.90147L-0.722168 4.05099L0.14071 4.89502L-0.0855762 6.11111L0.944499 5.41666L1.97457 6.11111L1.74829 4.89502L2.61117 4.05099L1.44128 3.90147L0.944507 2.77778Z"
          fill="#F5F7F8"
        />
      </g>
    </svg>
  );
};

export default EnglishFlag;
