import React from "react";

const ProductLike = ({ like, toggleWishlist }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      className={like ? "like_item active_like" : "like_item"}
      onClick={toggleWishlist}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.66667 7.17129C1.66667 4.59141 3.75808 2.5 6.33796 2.5C7.26019 2.5 8.16179 2.77298 8.92913 3.28454L10 3.99846L11.0709 3.28454C11.8382 2.77298 12.7398 2.5 13.662 2.5C16.2419 2.5 18.3333 4.59141 18.3333 7.17129V7.78652C18.3333 9.4248 17.6825 10.996 16.5241 12.1544L11.7678 16.9107C10.7915 17.8871 9.20855 17.8871 8.23224 16.9107L3.47592 12.1544C2.31748 10.996 1.66667 9.4248 1.66667 7.78652V7.17129Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ProductLike;
