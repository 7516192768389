import { useState, useEffect, useContext } from "react";
import Karzina from "../../assets/frags/karzina";
import Decrease from "../../assets/frags/decrease";
import Increase from "../../assets/frags/increase";
import Cookies from "js-cookie";
import { useLocale } from "next-intl";
import AuthContext from "../../context/authContext";
import { formatCurrency } from "@/lib/functions";
import { DetelPageContext } from "../../context/detelcontex";

export default function ProductCardBottom({ data }) {
  const [showQuantity, setShowQuantity] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const locale = useLocale();
  const { toggleShowLogin } = useContext(AuthContext);
  const { setShowmodal } = useContext(DetelPageContext);

  useEffect(() => {
    setQuantity(1);
    setShowQuantity(false);
  }, [data]);

  const handleAddToCart = () => {
    if (data?.count > 0) {
      setShowQuantity(true);
    }
  };

  const handleIncrease = () => {
    setQuantity((prevQuantity) => {
      if (prevQuantity < data.count) {
        return prevQuantity + 1;
      }
      return prevQuantity;
    });
  };

  const handleDecrease = () => {
    setQuantity((prev) => {
      if (prev <= 1) {
        setShowQuantity(false);
        return 1;
      }
      return prev - 1;
    });
  };

  const addBasket = async () => {
    try {
      const token = Cookies.get("access_token");

      if (!token) {
        toggleShowLogin(true);
        setShowmodal({
          showing: false,
        });
        return;
      }

      const response = await fetch(
        `${process.env.NEXT_PUBLIC_API}/api/v1/client/basket/create`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "Accept-Language": locale,
          },
          body: JSON.stringify([
            {
              productItemId: data?.id,
              count: quantity,
            },
          ]),
        },
      );

      if (response.status === 401) {
        toggleShowLogin(true);
        setShowmodal({
          showing: false,
        });
        return;
      }

      if (!response.ok) throw new Error("Server error");

      const result = await response.json();
      console.log("Muvaffaqiyatli qo‘shildi:", result);
      setShowmodal({ showing: false });
      alert("Muvaffaqiyatli qo‘shildi:", result);
    } catch (error) {
      console.error("Basketga qo'shishda xatolik yuz berdi:", error.message);
    }
  };

  const formattedPrice = formatCurrency(data?.price * quantity);

  return (
    <div className="product_price">
      <span className="product_price_headline">Цена</span>
      <span className="product_price_sum">{formattedPrice}</span>
      {showQuantity ? (
        <div className="product_price_top">
          <button
            className="product_price_back"
            onClick={handleDecrease}
            disabled={quantity <= 0}
          >
            <Decrease />
          </button>
          <span className="product_card_bottom_add_result">{quantity} шт</span>

          <button
            className={`product_price_add ${
              quantity >= data?.count ? "active__btn " : ""
            }`}
            onClick={handleIncrease}
            disabled={quantity >= data?.count}
          >
            <Increase />
          </button>
          <button
            className="product_price_add product_crud"
            onClick={addBasket}
          >
            <Karzina />
            Корзина
          </button>
        </div>
      ) : (
        <div className="product_price_wrap">
          <button
            className={`product_price_add ${
              !data || data?.length === 0 || data?.count <= 0
                ? "active__btnsecond"
                : ""
            }`}
            onClick={handleAddToCart}
            disabled={!data || data?.length === 0 || data?.count <= 0}
          >
            <Karzina />
            Добавить в корзину
          </button>
          <button className="product_price_like"></button>
        </div>
      )}
      <span className="product_price_delivery">
        Служба доставки в течение 2-3 дней.
      </span>
    </div>
  );
}
