"use client";

import { useState, useRef, useEffect, useContext } from "react";
import SearchSvg from "../../assets/frags/searchSvg";
import Cookies from "js-cookie";
import Searchcard from "./searchcard";
import { useRouter } from "next/navigation";
import { useLocale } from "next-intl";
import AuthContext from "../../context/authContext";
import ClickLoading from "../clickLoading";

export default function Search({ dictionary }) {
  const [results, setResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const debounceTimeout = useRef(null);
  const router = useRouter();
  const locale = useLocale();
  const { updateSearch, search } = useContext(AuthContext);

  const token = Cookies.get("access_token");

  const fetchSearchResults = async (query) => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `${process.env.NEXT_PUBLIC_API}/api/v1/search/${query}`,
        {
          method: "GET",
          headers: {
            Hostname: "localhost",
            "Accept-Language": locale,
          },
        },
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }

      const data = await response.json();
      setResults(data);
    } catch (error) {
      console.error("Search error:", error);
      setResults([]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (search.trim()) {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
      debounceTimeout.current = setTimeout(() => {
        fetchSearchResults(search.trim());
      }, 500);
    } else {
      setResults([]);
    }

    return () => {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
    };
  }, [search]);

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      router.push(
        `/${locale}/product/category/0babd496-c5bf-4356-9803-0f5b1b406e8c`,
      );
    }
  };

  const handleButtonClick = () => {
    router.push(
      `/${locale}/product/category/0babd496-c5bf-4356-9803-0f5b1b406e8c`,
    );
  };

  return (
    <div className="search">
      <div className="wrapper">
        <input
          className="input_default"
          type="text"
          placeholder={dictionary.search.placeholder}
          value={search}
          onChange={(event) => updateSearch(event.target.value)}
          onKeyDown={handleKeyPress}
        />
        <button
          role="button"
          aria-label="Search"
          type="submit"
          className="search-button"
          onClick={handleButtonClick}
        >
          <SearchSvg />
        </button>
        {search && (
          <button
            onClick={handleButtonClick}
            className="routes"
            style={{ display: "inline-block", marginLeft: "10px" }}
          >
            {dictionary.search.secondbtn}
          </button>
        )}
      </div>
      {results?.data?.length > 0 ? (
        <div className="wrapper_response">
          {results.data.map((item, index) => (
            <Searchcard
              key={index}
              data={item}
              results={results}
              setSearch={updateSearch}
            />
          ))}
        </div>
      ) : null}
    </div>
  );
}
