export default function InfoSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.66699 10C1.66699 5.39763 5.39795 1.66667 10.0003 1.66667C14.6027 1.66667 18.3337 5.39763 18.3337 10C18.3337 14.6024 14.6027 18.3333 10.0003 18.3333C5.39795 18.3333 1.66699 14.6024 1.66699 10ZM8.15132 5.23113C7.224 5.84935 6.66699 6.89012 6.66699 8.00463V8.33334C6.66699 8.79357 7.04009 9.16667 7.50033 9.16667C7.96056 9.16667 8.33366 8.79357 8.33366 8.33334V8.00463C8.33366 7.44737 8.61216 6.92699 9.07583 6.61788C9.63566 6.24466 10.365 6.24466 10.9248 6.61788L11.0712 6.71546C11.4434 6.96361 11.667 7.38136 11.667 7.82871C11.667 8.56764 11.068 9.16667 10.329 9.16667H10.2276C9.64184 9.16667 9.16699 9.64152 9.16699 10.2273V11.6667C9.16699 12.1269 9.54009 12.5 10.0003 12.5C10.4606 12.5 10.8337 12.1269 10.8337 11.6667V10.7911C12.2528 10.5512 13.3337 9.31615 13.3337 7.82871C13.3337 6.8241 12.8316 5.88597 11.9957 5.32871L11.8493 5.23113C10.7297 4.48468 9.27099 4.48468 8.15132 5.23113ZM10.0003 15C10.4606 15 10.8337 14.6269 10.8337 14.1667C10.8337 13.7064 10.4606 13.3333 10.0003 13.3333C9.54009 13.3333 9.16699 13.7064 9.16699 14.1667C9.16699 14.6269 9.54009 15 10.0003 15Z"
        fill="#000000"
      />
    </svg>
  );
}
