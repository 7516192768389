"use client";

import { useContext, useEffect, useState } from "react";
import VerificationInput from "react-verification-input";
import CloseSvg from "../../assets/frags/closeSvg";
import AuthContext from "../../context/authContext";
import ClickLoading from "../clickLoading";
import Bg from "./bg";
import axios from "axios";

export default function SmsCode() {
  const [code, setCode] = useState("");
  const [counter, setCounter] = useState(60);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [again, SetAgain] = useState(false);
  const { setSmsCode, telephone } = useContext(AuthContext);

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
  };

  useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
    counter === 0 && SetAgain(true);
  }, [counter]);

  const sendAgain = () => {
    setCounter(60);
    SetAgain(false);
    axios
      .post(
        ``,
        {
          phone: `+${telephone}`,
        },
        { headers },
      )
      .then(
        (response) => {
          if (response.data.status === 0) {
          } else if (response.data.status === 1) {
          }
        },
        (error) => {
          console.log(error);
        },
      );
  };

  return (
    <>
      <div className="main_modal sms_code">
        <h1>Подтверждение</h1>

        <span className="main_modal_info">Введите код из СМС</span>

        <form onSubmit={handleSubmit}>
          <VerificationInput
            onChange={(code) => setCode(code)}
            length={6}
            autoFocus={true}
            validChars="0-9"
            inputProps={{
              autoComplete: "one-time-code",
              code: "code",
              id: "code",
              autoFocus: true,
              required: true,
            }}
            classNames={{
              characterInactive: "character_inactive",
            }}
          />

          <p
            style={{
              cursor: "pointer",
            }}
            className="error-message"
          >
            {error}
          </p>

          <button type="submit" className="main_modal_btn">
            Подвердить
          </button>

          <span className="sms_password_timer">
            Прислать код повторно через {counter}
          </span>

          <span
            onClick={sendAgain}
            className={again ? "again" : "again again_none"}
          >
            Отправить снова
          </span>
        </form>

        <button className="close" onClick={() => setSmsCode(false)}>
          <CloseSvg />
        </button>
        {loading && <ClickLoading />}
      </div>
      <Bg state={setSmsCode} />
    </>
  );
}
