"use client";

import { useContext, useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import Image from "next/image";
import Link from "next/link";
import RatingSvg from "../assets/frags/ratingSvg";
import ProductLike from "../assets/frags/productLike";
import { useRouter } from "next/navigation";
import ProductBasket from "../assets/frags/productbasket";
import { formatCurrency, getImageUrl } from "@/lib/functions";
import { useLocale } from "next-intl";
import AuthContext from "../context/authContext";
import { DetelPageContext } from "../context/detelcontex";
import ProductModalBox from "./identifiers/ProductModal";

export default function ProductCard({
  name,
  image,
  price,
  deskaunt,
  rating,
  like: initialLike,
  url,
  dictionary,
}) {
  const API_URL = `${process.env.NEXT_PUBLIC_API}`;
  const router = useRouter();
  const token = Cookies.get("access_token");
  const { refresh } = useRouter();
  const locale = useLocale();
  const { toggleShowLogin } = useContext(AuthContext);
  const { setShowmodal } = useContext(DetelPageContext);

  const headers = {
    Authorization: `Bearer ${token}`,
    Hostname: "localhost",
    "Content-Type": "application/json",
    "Accept-Language": locale,
  };

  const [like, setLike] = useState(initialLike);
  const handleToggleWishlist = () => {
    if (!like) {
      axios
        .post(
          `${API_URL}/api/v1/client/wishlist/create`,
          {
            productId: url,
          },
          {
            headers: headers,
          },
        )
        .then((res) => {
          setLike(true);
          refresh();
        })
        .catch((err) => {
          console.log(err.status, "This is error");
          if (err.status === 401) {
            toggleShowLogin(true);
          }
        });
    } else {
      axios
        .delete(`${API_URL}/api/v1/client/wishlist/delete/${url}`, {
          headers: headers,
        })
        .then((res) => {
          console.log(res, "product has been deleted");
          setLike(false);
          refresh();
        });
    }
  };

  const handleShowModal = () => {
    setShowmodal({
      showing: true,
      detelId: url,
    });
  };

  return (
    <div className="product_card">
      <Link href={`${locale}/product/${url}`} className="product_card_main">
        <div className="product_card_img_block">
          <Image
            src={getImageUrl(image)}
            alt="product img"
            width={200}
            height={200}
            unoptimized
            loading="lazy"
          />
        </div>
        <div className="product_card_text_wrap">
          <h4 className="headline">{name}</h4>
          <div className="product_card_prices">
            <span>{formatCurrency(price)}</span>
            {deskaunt && (
              <span className="price_to_compare">
                {formatCurrency(deskaunt)}
              </span>
            )}
          </div>
        </div>
      </Link>
      <ProductLike like={like} toggleWishlist={handleToggleWishlist} />
      <span className="rating">
        <RatingSvg />
        {rating || 0}
      </span>
      <div className="product_card_bottom">
        <button
          type="button"
          className="product_card_bottom_btn"
          onClick={handleShowModal}
        >
          {dictionary?.products?.btn}
          <ProductBasket />
        </button>
        <ProductModalBox />
      </div>
    </div>
  );
}
