"use client";

import { useContext } from "react";
import { DetelPageContext } from "../../context/detelcontex";
import CloseSvg from "../../assets/frags/closeSvg";

function HomeModal({ children }) {
  const { showmodal, setShowmodal } = useContext(DetelPageContext);

  if (!showmodal?.showing) return null;

  return (
    <div className="home-page">
      <div className="home-modal">
        <CloseSvg
          onClick={() => setShowmodal({ showing: false, detelId: null })}
        />
        <div className="product">
          <div className="product_header container">
            <div className="product_header_right">
              <div className="product_modal">{children}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeModal;
