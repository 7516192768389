import React from "react";

const Decrease = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.833 9.99984C15.833 10.4601 15.4599 10.8332 14.9997 10.8332L4.99967 10.8332C4.53944 10.8332 4.16634 10.4601 4.16634 9.99984C4.16634 9.5396 4.53944 9.1665 4.99967 9.1665L14.9997 9.1665C15.4599 9.1665 15.833 9.5396 15.833 9.99984Z"
        fill="#9797A8"
      />
    </svg>
  );
};

export default Decrease;
