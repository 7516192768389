import React from "react";
import Product from "./navbar/product";
import Category from "./navbar/category";
import LinkNav from "./navbar/link";

export const DynamicCollectionNav = ({ data }) => {
  if (data.sectionType === "PRODUCT") {
    return <Product data={data} />;
  }
  if (data.sectionType === "CATEGORY") {
    return <Category data={data} />;
  }
  if (data.sectionType === "INFO") {
    return <LinkNav data={data} />;
  }

  return <div>Unknown Type: {data.type}</div>;
};
