"use client";

import { useLocale } from "next-intl";
import { createContext, useContext, useState } from "react";
import AuthContext from "./authContext";

const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [cart, setCart] = useState(() => {
    if (typeof window === "undefined") {
      return [];
    }
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem("cart");
      // Parse stored json or if none return initialValue
      return item?.cartItems ? JSON.parse(item) : [];
    } catch (error) {
      // If error also return initialValue
      console.log(error);
      return [];
    }
  });
  const [cart2, setCart2] = useState([]);
  const [favorites, setFavorites] = useState([]);
  const [refreshCart, setRefreshCart] = useState(false);
  const [refreshFavorites, setRefreshFavorites] = useState(false);
  const { token, setToken } = useContext(AuthContext);
  const locale = useLocale();

  const headers = {
    language: locale,
    "x-access-token": token,
    "Cache-Control": "no-cache",
  };

  const setCartToState = () => {
    setCart(
      localStorage.getItem("cart")
        ? JSON.parse(localStorage.getItem("cart"))
        : localStorage.setItem("cart", JSON.stringify({ cartItems: [] })),
    );
  };

  const addItemToCart = async ({
    product,
    product_id,
    name,
    price,
    image,
    delivery,
    stock,
    seller,
    quantity = 1,
  }) => {
    const item = {
      product,
      product_id,
      name,
      price,
      image,
      delivery,
      stock,
      seller,
      quantity,
    };

    const isItemExist = cart?.cartItems?.find(
      (i) => i.product === item.product,
    );

    let newCartItems;

    if (isItemExist) {
      newCartItems = cart?.cartItems?.map((i) =>
        i.product === isItemExist.product ? item : i,
      );
    } else {
      newCartItems = [...(cart?.cartItems || []), item];
    }

    localStorage.setItem("cart", JSON.stringify({ cartItems: newCartItems }));
    setCartToState();
  };

  const deleteItemFromCart = (id) => {
    const newCartItems = cart?.cartItems?.filter((i) => i.product !== id);

    localStorage.setItem("cart", JSON.stringify({ cartItems: newCartItems }));
    setCartToState();
  };

  const amountWithoutTax = cart?.cartItems?.reduce(
    (acc, item) => acc + item.quantity * item.price,
    0,
  );

  const amountdelivery = cart?.cartItems?.reduce(
    (acc, item) => acc + item.quantity * item.delivery,
    0,
  );

  const amountdelivery2 = cart2?.items?.reduce(
    (acc, item) => acc + item.delivery_price,
    0,
  );

  return (
    <CartContext.Provider
      value={{
        cart,
        setCart,
        amountWithoutTax,
        amountdelivery,
        amountdelivery2,
        addItemToCart,
        deleteItemFromCart,
        setCart2,
        cart2,
        refreshCart,
        setRefreshCart,
        refreshFavorites,
        setRefreshFavorites,
        favorites,
        setFavorites,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export default CartContext;
