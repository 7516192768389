import React from "react";

const RussionFlag = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_614_5063"
        // style="mask-type:alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="20"
        height="20"
      >
        <circle cx="10" cy="10" r="10" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_614_5063)">
        <rect
          y="20"
          width="6.66667"
          height="20"
          transform="rotate(-90 0 20)"
          fill="#DD2033"
        />
        <rect
          y="13.3333"
          width="6.66667"
          height="20"
          transform="rotate(-90 0 13.3333)"
          fill="#004692"
        />
        <rect
          y="6.66663"
          width="6.66667"
          height="20"
          transform="rotate(-90 0 6.66663)"
          fill="#F5F7F8"
        />
      </g>
    </svg>
  );
};

export default RussionFlag;
