"use client";

import { useState, createContext } from "react";

export const DetelPageContext = createContext();

export const ProviderDetelPage = ({ children }) => {
  const [detelpage, setDetelPage] = useState(false);
  const [showmodal, setShowmodal] = useState({
    showing: false,
    detelId: null,
  });

  return (
    <DetelPageContext.Provider
      value={{ detelpage, setDetelPage, showmodal, setShowmodal }}
    >
      {children}
    </DetelPageContext.Provider>
  );
};
