"use client";

import React, { useState, useEffect, useContext } from "react";
import { useForm, Controller } from "react-hook-form";
import ColorComponent from "../../components/identifiers/color";
import ProductSelectComponent from "../../components/identifiers/store";
import StarSvg from "../../assets/frags/starSvg";
import InfoSvg from "../../assets/frags/infoSvg";
import ProductPrice from "./productPrice";
import { DetelPageContext } from "../../context/detelcontex";

const ProductComponent = ({ product }) => {
  const [identifiers, setIdentifiers] = useState([]);
  const { detelpage: detailPage, setDetelPage } = useContext(DetelPageContext);
  const { control, watch, setValue } = useForm();

  const identifierComponents = {
    COLOR: ColorComponent,
    SELECT: ProductSelectComponent,
  };

  useEffect(() => {
    if (product?.items) {
      initializeIdentifiers();
    }
  }, [product]);

  const watchForm = watch();
  useEffect(() => {
    if (identifiers.length > 0) {
      updateDisabledStates();
      updateSelectedItem();
    }
  }, [JSON.stringify(watchForm)]);

  const initializeIdentifiers = () => {
    const newIdentifiers = [];
    product.items.forEach((item) => {
      item.identifiers.forEach((identifier) => {
        const existing = newIdentifiers.find(
          (i) => i.id === identifier.unitKey,
        );

        if (existing) {
          if (!existing.values.some((v) => v.value === identifier.itemValue)) {
            existing.values.push({
              value: identifier.itemValue,
              label: identifier.itemLabel,
              disabled: false,
            });
          }
        } else {
          setValue(identifier.unitKey, null);
          newIdentifiers.push({
            name: identifier.unitLabel,
            id: identifier.unitKey,
            type: identifier.unitType,
            values: [
              {
                value: identifier.itemValue,
                label: identifier.itemLabel,
                disabled: false,
              },
            ],
          });
        }
      });
    });

    setIdentifiers(newIdentifiers);
  };

  const updateSelectedItem = () => {
    const selectedItem = product.items.find((item) =>
      identifiers.every((identifier) =>
        item.identifiers.some(
          (id) =>
            id.unitKey === identifier.id &&
            id.itemValue === watchForm[identifier.id],
        ),
      ),
    );
    setDetelPage(selectedItem || null);
  };

  const updateDisabledStates = () => {
    setIdentifiers((prevIdentifiers) =>
      prevIdentifiers.map((identifier) => ({
        ...identifier,
        values: identifier.values.map((value) => ({
          ...value,
          disabled: !isOptionAvailable(identifier.id, value.value),
        })),
      })),
    );
  };

  const isOptionAvailable = (identifierKey, identifierValue) => {
    const currentSelections = Object.entries(watchForm).filter(
      ([key, value]) => value !== null && key !== identifierKey,
    );

    return product.items.some((item) => {
      const matchesCurrentSelections = currentSelections.every(([key, value]) =>
        item.identifiers.some(
          (id) => id.unitKey === key && id.itemValue === value,
        ),
      );

      const matchesThisOption = item.identifiers.some(
        (id) =>
          id.unitKey === identifierKey && id.itemValue === identifierValue,
      );

      return matchesCurrentSelections && matchesThisOption;
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  return (
    <div className="product_info">
      <div className="product_info_header">
        <div className="product_info_header_review_count">
          <StarSvg />
          <span>{product?.rating}.0</span>
        </div>
        <span className="product_info_header_review">24 отзыва</span>
      </div>
      <h3 className="product_info_title">{product?.name}</h3>

      <form onSubmit={handleSubmit}>
        {identifiers.map((identifier) => {
          const Component = identifierComponents[identifier.type];
          if (!Component) {
            console.error(`Component for type ${identifier.type} not found`);
            return null;
          }
          return (
            <div key={identifier.id} className="product_info_select">
              <label className="product_info_select_title">
                {identifier.name}
              </label>
              <Controller
                name={identifier.id}
                control={control}
                render={({ field }) => (
                  <Component
                    {...field}
                    options={identifier.values}
                    onChange={(value) => setValue(identifier.id, value)}
                  />
                )}
              />
            </div>
          );
        })}
      </form>

      <div className="product_info_navigate">
        <InfoSvg />
        <h4>Подробнее о продукте</h4>
        <span>Перейти к описанию</span>
      </div>

      <ProductPrice data={detailPage} />
    </div>
  );
};

export default ProductComponent;
