import { useLocale } from "next-intl";
import React from "react";

const LinkNav = ({ data }) => {
  return (
    <li className="header_list_item">
      <a href={`${data?.params?.items[0]?.info}`}>{data.params.name}</a>
    </li>
  );
};

export default LinkNav;
