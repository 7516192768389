import React from "react";

const ColorComponent = ({ value, options, onChange }) => {
  return (
    <div className="product_info_select_color">
      {options.map((element) => (
        <div
          key={element.value}
          onClick={() => {
            if (!element.disabled) {
              onChange(element.value);
            }
          }}
          role="button"
          aria-disabled={element?.disabled}
          className={`color-option ${element.disabled ? "disabled" : ""} ${
            value === element.value ? "active-color" : ""
          }`}
          style={{ backgroundColor: `${element.value}` }}
        ></div>
      ))}
    </div>
  );
};

export default ColorComponent;
