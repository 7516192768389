"use client";

import { useEffect } from "react";
import { useRouter } from "next/navigation";

export default function DeviceRedirect() {
  const router = useRouter();

  useEffect(() => {
    if (window.innerWidth < 1024) {
      router.push("https://m.demo.kyuu.io");
    }
  }, [router]);

  return null;
}
