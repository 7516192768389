"use client";

import { createContext, useState, useCallback, useMemo } from "react";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [showLogin, setShowLogin] = useState(false);
  const [smsCode, setSmsCode] = useState(false);
  const [showUserInfo, setShowUserInfo] = useState(false);
  const [telephone, setTelephone] = useState("");
  const [search, setSearch] = useState("");

  const toggleShowLogin = useCallback(() => setShowLogin((prev) => !prev), []);
  const toggleSmsCode = useCallback(() => setSmsCode((prev) => !prev), []);
  const toggleShowUserInfo = useCallback(
    () => setShowUserInfo((prev) => !prev),
    [],
  );
  const updateTelephone = useCallback(
    (newNumber) => setTelephone(newNumber),
    [],
  );
  const updateSearch = useCallback((query) => setSearch(query), []);

  const contextValue = useMemo(
    () => ({
      showLogin,
      toggleShowLogin,
      smsCode,
      toggleSmsCode,
      showUserInfo,
      toggleShowUserInfo,
      telephone,
      updateTelephone,
      search,
      updateSearch,
    }),
    [showLogin, smsCode, showUserInfo, telephone, search],
  );

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

// export const useAuth = () => useContext(AuthContext);

export default AuthContext;
