import { useContext, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import PhoneInput from "react-phone-input-2";
import AuthContext from "../../context/authContext";
import { useRouter } from "next/navigation";
import CloseSvg from "../../assets/frags/closeSvg";
import Bg from "./bg";
import ClickLoading from "../clickLoading";
import axios from "axios";
import { getStaticUrl } from "@/lib/functions";
import Cookies from "js-cookie";
import { useLocale } from "next-intl";

export default function Login() {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const { refresh } = useRouter();
  const {
    control,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm({ mode: "onChange" });
  const { locale } = useLocale();

  const { toggleShowLogin } = useContext(AuthContext);

  const onSubmit = async (formData) => {
    setLoading(true);
    setError(null);

    if (formData.phone.length < 12) {
      setError("Telefon raqami to'liq emas");
      setLoading(false);
      return;
    }

    const requestData = {
      phone: formData.phone,
      deviceType: "WEB",
      deviceName: navigator.userAgent,
    };

    const headers = {
      Hostname: "localhost",
      "Accept-Language": locale,
    };

    try {
      const response = await axios.post(
        getStaticUrl(`/api/client/auth/token`),
        requestData,
        { headers },
      );

      toggleShowLogin(false);
      Cookies.set("access_token", response.data.access_token, {
        secure: true,
      });
      Cookies.set("refresh_token", response.data.refresh_token, {
        secure: true,
      });
      refresh();
    } catch (error) {
      console.error("Error during request:", error);
      setError("An error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="login main_modal">
        <h1>Вход в аккаунт</h1>
        <span className="main_modal_info">
          Мы отправим проверочный код на введенный номер по SMS.
        </span>
        <form onSubmit={handleSubmit(onSubmit)}>
          <label htmlFor="phone">Телефон</label>
          <Controller
            control={control}
            name="phone"
            rules={{
              required: "Telefon raqamini kiriting",
              validate: (value) =>
                value.length >= 12 || "Telefon raqami to'liq emas",
            }}
            render={({ field: { ref, ...field } }) => (
              <PhoneInput
                {...field}
                country={"uz"}
                onlyCountries={["uz"]}
                masks={{ uz: "(..) ...-..-.." }}
                countryCodeEditable={false}
                specialLabel=""
                onChange={(value) => field.onChange(value)}
                inputProps={{
                  autoFocus: true,
                  ref,
                  required: true,
                }}
                className={`${errors.phone ? "phone_error" : ""} phone_input`}
              />
            )}
          />
          {errors.phone && (
            <span className="error-message text-red-500">
              {errors.phone.message}
            </span>
          )}
          {error && <span className="error-message">{error}</span>}
          <button
            type="submit"
            className="main_modal_btn"
            disabled={loading || !isValid}
          >
            {loading ? "Loading..." : "Получить код"}
          </button>
        </form>
        <span className="modal_main_offer">
          Avtotizatsiyadan o`tish orqali siz{" "}
          <b>shaxsiy ma`lumotlarni qayta ishlash siyosatiga</b> rozilik
          bildirasiz
        </span>
        <button className="close" onClick={() => toggleShowLogin(false)}>
          <CloseSvg />
        </button>
        {loading && <ClickLoading />}
      </div>
      <Bg state={toggleShowLogin} />
    </>
  );
}
