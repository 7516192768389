"use client";

import React, { useContext, useEffect, useState } from "react";
import ProductComponent from "../../product/[productId]/productInfo";
import HomeModal from "../modals/homeModal";
import { DetelPageContext } from "../../context/detelcontex";
import axios from "axios";
import { getStaticUrl } from "@/lib/functions";
import { useLocale } from "next-intl";

function ProductModalBox({ products }) {
  const [data, setData] = useState(null);
  const { showmodal } = useContext(DetelPageContext);
  const locale = useLocale();

  useEffect(() => {
    if (showmodal.detelId) {
      axios
        .get(getStaticUrl(`/api/4c/product/info/${showmodal.detelId}`), {
          headers: {
            Hostname: "localhost",
            "Accept-Language": locale,
          },
        })
        .then((res) => {
          setData(res.data);
        })
        .catch((err) => {
          console.error("Error fetching product info:", err);
        });
    }
  }, [showmodal.detelId, locale]);

  return (
    <HomeModal>
      <ProductComponent product={data?.data} />
    </HomeModal>
  );
}

export default ProductModalBox;
