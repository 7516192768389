"use client";

import { useLocale } from "next-intl";
import Image from "next/image";
import Link from "next/link";
import { useState } from "react";
import Skeleton from "react-loading-skeleton";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { getImageUrl } from "@/lib/functions";

export default function Banner({ data }) {
  const locale = useLocale();
  const [loading] = useState(false);

  const settings = {
    dots: true,
    infinite: true,
    fade: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    waitForAnimate: false,
    autoplay: true,
    autoplaySpeed: 2000,
    cssEase: "linear",
  };

  return (
    <div className="slider container">
      {loading ? (
        <div className="slick-slider">
          <Skeleton width="100%" height={420} />
        </div>
      ) : (
        <Slider {...settings}>
          {data?.params.items?.map((data, index) => (
            <Link
              className="custom-carusel"
              key={data.id}
              href={
                data.type === "CATEGORY"
                  ? `${locale}/product/category/${data.id}`
                  : `${locale}/product/${data.id}`
              }
            >
              <Image
                unoptimized
                src={getImageUrl(data?.imageId)}
                alt={`Banner image ${index + 1}`}
                width={1920}
                height={1080}
                sizes="100vw"
                style={{ width: "100%", height: "auto" }}
                className="banner_img"
              />
            </Link>
          ))}
        </Slider>
      )}
    </div>
  );
}
